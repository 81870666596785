@font-face {
    font-family: 'NouvelR Bold';
    src: local('NouvelR Bold'),
    url(../fonts/NouvelR-Bold.woff2) format("woff2");
}

@font-face {
    font-family: 'NouvelR Regular';
    src: local('NouvelR Regular'),
    url(../fonts/NouvelR-Regular.woff2) format("woff2");
}

@font-face {
    font-family: 'NouvelR Light';
    src: local('NouvelR Light'),
    url(../fonts/NouvelR-Light.woff2) format("woff2");
}

body {
    margin: 0;
    font-family: "NouvelR Regular", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif;
}